@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  nav {
    @apply bg-navbarBg bg-no-repeat bg-cover;
  }
  .nav-item {
    @apply font-inika font-bold text-white text-lg md:text-sm lg:text-lg uppercase;
  }
}
