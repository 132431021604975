@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  * {
    box-sizing: border-box;
  }
  body {
    @apply bg-[#0A626D];
  }
  .section {
    @apply md:max-w-6xl mx-auto text-center;
  }
  .section-sm {
    @apply max-w-4xl mx-auto text-center;
  }
  .section-title {
    @apply font-enriqueta text-5xl font-bold text-[#FFE600] text-center md:text-left;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .section-subtitle {
    @apply font-enriqueta text-2xl py-5;
  }

  .section-topic {
    @apply font-enriqueta font-bold text-4xl text-[#FFE600] text-center md:text-left;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .section-btn {
    @apply bg-[#29B168] px-8 py-2 my-10 text-white text-xl font-enriqueta;
  }

  .section-div-img {
    @apply flex flex-col items-center md:flex-row my-10 mx-auto text-lg;
  }

  .section-img {
    @apply bg-sectionImageBg bg-no-repeat bg-cover py-10 px-5 mx-10 md:mx-auto;
  }
}
