@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .connect-btn {
    @apply font-inika px-4 py-4 text-lg font-bold uppercase mx-auto;
    background: url("../images/btn-img.png");
    background-repeat: no-repeat;
    background-size: cover;
    /* margin: 0px auto; */
  }
}
