@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .banner-btn {
    @apply font-enriqueta px-5 py-10 text-white text-3xl font-bold uppercase mx-auto;
    background: url("../images/bannerBtn.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 700;
  }
}
